<template>
  <div>
    <div class="header text-center">
      <h3 class="title">PORTFÓLIO</h3>
    </div>
    <div class="md-layout">
      <div class="md-layout-item portfolio">
        <md-card>
          <md-card-content>
            <div class="md-tabs-navigation md-elevation-0" style="background-color: #00122a">
              <button type="button" @click="changeTab($event, 'default')" class="md-button md-tab-nav-button md-theme-default md-active">
                <div class="md-ripple">
                  <div class="md-button-content">Carteira padrão</div>
                </div>
              </button>
              <button type="button" @click="changeTab($event, 'others')" class="md-button md-tab-nav-button md-theme-default">
                <div class="md-ripple">
                  <div class="md-button-content">Outras posições</div>
                </div>
              </button>
              <span class="md-tabs-indicator md-tabs-indicator-right" style="left: 75px; right: calc(100% - 339px)"></span>
            </div>
            <md-table v-if="isPortfolioLoaded && selectedTab == 'default'" v-model="portfolio" md-card md-sort="stockId" md-sort-order="asc">
              <md-table-empty-state
                md-label="Nenhum ativo encontrado..."
               >
              </md-table-empty-state>
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Código" data-title="Código" md-sort-by="stockId"><md-chip>{{ item.stockId }}</md-chip></md-table-cell>
                <md-table-cell md-label="Corretora" data-title="Corretora">{{ item.broker.name }}</md-table-cell>
                <md-table-cell md-label="Preço" data-title="Preço" md-numeric>{{ item.price | CurrencyFormat }}</md-table-cell>
                <md-table-cell md-label="Preço Ajustado" data-title="Preço Ajustado" md-numeric>
                  <md-button v-show="!adjustedPosition[`${item.stockId}-${item.broker.brokerId}`]" style="width: 40%;" @click="getCalcAdjustedPosition(item.stockId, item.broker.brokerId)"> Calcular </md-button>
                  <div v-show="adjustedPosition[`${item.stockId}-${item.broker.brokerId}`]">{{ adjustedPosition[`${item.stockId}-${item.broker.brokerId}`] | CurrencyFormat }} </div>

                </md-table-cell>
                <!-- <md-table-cell md-label="Var." data-title="Var.">{{ item.stockQuote.changePercent | ValueFormat }}%</md-table-cell>
                <md-table-cell md-label="Min." data-title="Min.">{{ item.stockQuote.low | ValueFormat }}</md-table-cell>
                <md-table-cell md-label="Máx." data-title="Máx.">{{ item.stockQuote.high | ValueFormat }}</md-table-cell>
                <md-table-cell md-label="Aber." data-title="Aber.">{{ item.stockQuote.open | ValueFormat }}</md-table-cell> -->
                <md-table-cell md-label="Qtde" data-title="Qtde" md-numeric>{{ item.shares | TruncateFormat }}</md-table-cell>
                <md-table-cell md-label="Val. Invest." data-title="Val. Invest." md-numeric>{{ item.cost | CurrencyFormat }}</md-table-cell>
                <md-table-cell md-label="P.Médio" data-title="P.Médio" md-numeric>{{ item.averagePrice | CurrencyFormat }}</md-table-cell>
                <md-table-cell md-label="Valor Atual" data-title="Valor Atual" md-numeric>{{ item.mktValue | CurrencyFormat }}</md-table-cell>
                <md-table-cell md-label="Result." data-title="Result." md-numeric v-currency-color="item.gain">{{ item.gain | CurrencyFormat }}</md-table-cell>
                <md-table-cell md-label="Resut." data-title="Resut." md-numeric v-currency-color="item.gainPercent">{{ item.gainPercent | ValueFormat }}%</md-table-cell>
              </md-table-row>
            </md-table>
            <md-table v-if="isPortfolioOthersLoaded && selectedTab == 'others'" v-model="portfolioOthers" md-card>
              <md-table-empty-state
                md-label="Nenhum ativo encontrado..."
               >
              </md-table-empty-state>
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Código" data-title="Código"><md-chip>{{ item.symbol }}</md-chip></md-table-cell>
                <md-table-cell md-label="Vencimento" data-title="Vencimento"><md-chip>{{ item.dueDate | DateFormat }}</md-chip></md-table-cell>
                <md-table-cell md-label="Corretora" data-title="Corretora">{{ item.broker.name }}</md-table-cell>
                <md-table-cell md-label="Qtde" data-title="Qtde" md-numeric>{{ item.shares }}</md-table-cell>
                <md-table-cell md-label="Val. Invest." data-title="Val. Invest." md-numeric>{{ item.cost | CurrencyFormat }}</md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'

function currencyColor(el, binding){
    if (binding.value > 0){
      el.style.color = 'rgb(102, 187, 106)' 
    } else {
      el.style.color = 'rgb(244, 67, 54)'
    }
};

export default {
    data: () => ({
      portfolio: [],
      portfolioOthers: [],
      selectedTab: 'default',
      pageSize: 1000,
      loading: false,
      page: 1,
      isMobile: false,
      isPortfolioLoaded: false,
      isPortfolioOthersLoaded: false,
      adjustedPosition: {}
    }),
    directives: { currencyColor },
    methods: {
      async getPortfolio(page) {
        Swal.showLoading();
        try {
          var portfolio_list = null
          this.loading = true
          this.params = {
            token: this.$store.getters.token,
            page,
            pageSize: this.pageSize,
          }
          portfolio_list = await this.$store.dispatch('profile/getPortfolio', this.params)
          portfolio_list = await portfolio_list.items
          portfolio_list.forEach(portfolio => {
            this.portfolio = this.portfolio.concat(portfolio.portfolio)
          });
          this.loading = false
          this.isPortfolioLoaded = true;
        } catch (error) {
          console.log(error)
          await this.$store.dispatch("alerts/error", "Ocorreu um erro, tente novamente.");
        } finally {
          setTimeout(function () {
            Swal.close();
          }, 500);
        }
        
      },
      async getPortfolioOthers() {
        Swal.showLoading();
        try {
          var portfolio_others_list = null
          this.loading = true
          this.params = {
            token: this.$store.getters.token
          }
          portfolio_others_list = await this.$store.dispatch('profile/getPortfolioOthers', this.params)
          this.portfolioOthers = this.portfolioOthers.concat(portfolio_others_list)
          this.loading = false
          this.isPortfolioOthersLoaded = true;
        } catch (error) {
          console.log(error)
          await this.$store.dispatch("alerts/error", "Ocorreu um erro, tente novamente.");
        } finally {
          setTimeout(function () {
            Swal.close();
          }, 500);
        }
        
      },
      async getCalcAdjustedPosition(stockId, brokerId) {
        Swal.showLoading();
        var calcAdjustedPosition = null;
        try {
          this.loading = true
          this.params = {
            token: this.$store.getters.token,
            stockId: stockId,
            brokerId: brokerId
          }
          calcAdjustedPosition = await this.$store.dispatch('profile/getCalcAdjustedPosition', this.params);
          console.log(calcAdjustedPosition);
          this.$set(this.adjustedPosition, stockId + '-' + brokerId, calcAdjustedPosition.cost / calcAdjustedPosition.shares);
          console.log(stockId + '-' + brokerId);
          this.loading = false
        } catch (error) {
          console.log(error)
          await this.$store.dispatch("alerts/error", "Ocorreu um erro, tente novamente.");
        } finally {
          setTimeout(function () {
            Swal.close();
          }, 500);
        }
        
      },
      changeTab(event, selectedTab) {
        $('.md-active').removeClass('md-active')
        $(event.currentTarget).addClass('md-active')
        this.selectedTab = selectedTab
      },
      async seeMore() {
        this.page += 1
        this.getPortfolio(this.page)
      },
    },
    mounted() {
      this.isMobile = window.innerWidth < 790;
    },
    beforeMount: function () {
      this.$nextTick(function () {
        this.getPortfolio(this.page);
        this.getPortfolioOthers();
      })
    },
};
</script>
<style lang="scss">
  .portfolio{
    .md-table-cell-container{font-weight: 500;}
    .md-table-head-label{width: 100%;}
  }
</style>
